<template>
  <div>
    <c-table
      ref="table"
      title="수질 검사항목"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :rowKey="rowKey"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'water-inspect-item-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
  },
  data() {
    return {
      rowKey: 'envWaterMstInspectItemId',
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'envWaterMstInspectItemName',
            field: 'envWaterMstInspectItemName',
            label: '검사항목',
            align: 'center',
            sortable: true,
          },
          {
            name: 'legalStandard',
            field: 'legalStandard',
            label: '법적기준',
            align: 'center',
            type: 'cost',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        useFlag: 'Y',
      },
      LineItems: [],
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.mst.inspect.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '검사항목을 선택하세요.', // 공정을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
